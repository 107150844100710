<template>
    <SaveBvn/>
</template>

<script>
import SaveBvn from '../../components/auth/SaveBvn'
export default {

    components: {
        SaveBvn
    }
}
</script>

<style>

</style>
