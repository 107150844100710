<template>
  <div>
    <Wrapper>
      <Sidebar slot="sidebar"/>
      <Navbar slot="navbar"/>
      <Footer slot="footer"/>
      <div slot="content">
        <!-- <input type="text" v-model="filters.name.value"> -->
        <!-- <div class="pre-loader text-center" v-if="preLoader">
                    <span class="loader"></span>
        </div>-->
        <div class="pre-loader text-center" v-if="preLoader">
                    <span class="loader"></span>
                </div>
         
      <div :class="{'container':windowWidth > 992}">
          <div class="book-home-cover mt-4">
            <div class="row">
      
              <div class="col-xl-6 col-lg-6 col-md-6  col-12 col-sm-12 offset-md-3  offset-xl-3 offset-lg-3 mt-2">
                <!-- Next page -->
                <div style="max-width: 441px;
    margin: 0 auto;">
                <div class="booking-card" style="background:#E4FAE1">
                  <div style="position:relative">
                    <img
                      src="../../assets/images/bookinfo.png"
                      height="100"
                      style="    position: absolute;right: -11px;top: -10px;"
                    >
                  </div>
                  <div class="display-div mb-3">
                    <div style="display:flex; min-height:20px; align-items:center">
                      <img src="../../assets/images/infocol.png" class="mr-2" height="30">
                      <h5 style="color:#828282">Your bank details are required</h5>
                    </div>
                  </div>
                  <div style="color:#828282; min-height:30px;">
                    <p style="color:#4F4F4F">
            You need to provide your BVN for regulatory compliance, correctly identify you and provide you with full access to our services.
                      </p>
                  </div>
                 
                </div>
              

                

                <div class="booking-card mt-3">
                  <div style="position:relative">
                    <img
                      src="../../assets/images/bookamount.png"
                      height="70"
                      style="    position: absolute;right: -11px;top: -10px;"
                    >
                  </div>
                  <div class="display-div mb-3">
                    <div style=" min-height:20px; flex-grow:0; align-items:center; flex-basis:50%">
                      <p style="color:#828282; font-size:18px"> BVN  </p>
                      <div class="mt-2" style=" border-top:0.5px solid #E0E0E0;"></div>
                    </div>

                    
                  </div>
                  <div style="color:#828282;min-height:30px; position:relative">
                    <div style="display:flex">
                    <p style="flex-basis:100%">Please enter your 11 digit BVN  </p>
                    

                    </div>
                    <input
                      type="number"
                      class="text-center"
                      style="min-width:100%;border:0.5px solid #E0E0E0;border-radius: 5px;min-height:70px;background: #FAFAFA; font-weight:600"
                      autofocus
                      v-model="bvn"
                        maxlength="11"
                        minlength="11"
                        
                        @keyup="getBvnValue($event)"
                    >
                  </div>
                  <div style="color:#828282;min-height:30px; position:relative">
                    <div style="display:flex" class="mt-4">
                    <p style="flex-basis:100%">Bank Name</p>
                    

                    </div>
                       <select @change="getBankSelected($event)"
                                                            :class="{'disable-input':!editBankDetail, 'enable-input':editBankDetail}"
                                                            class="text-center"   style="min-width:100%;border:0.5px solid #E0E0E0;border-radius: 5px;min-height:70px;background: #FAFAFA; font-weight:600">
                                                            <option v-for="bank in bankNames" :value="bank.id"
                                                                :key="bank.id"
                                                                :selected="bank.name == userProfileInfo.bank_name">
                                                                {{bank.name}}</option>
                                                        </select>
                  </div>
                  <div style="color:#828282;min-height:30px; position:relative">
                    <div style="display:flex" class="mt-4">
                    <p style="flex-basis:100%">Account Number</p>
                    

                    </div>
                   <input type="text" autocomplete="off" 
                                                            :class="{'disable-input':!editBankDetail, 'enable-input':editBankDetail}"
                                                            v-model="userProfileInfo.bank_account_no" maxlength="10"
                                                            @input="getBankNames"   style="min-width:100%;border:0.5px solid #E0E0E0;border-radius: 5px;min-height:70px;background: #FAFAFA; font-weight:600">
                  </div>
     <div v-if="userProfileInfo.bank_account_name !== null"
                                                    class="animated fadeIn">

                                                    <p class="mt-3" style="color:#828282">{{tran.acc_name}}</p>
                                                    <p style="font-size:14px; font-weight:600"
                                                        v-if="userProfileInfo.bank_account_name !== null">
                                                        {{userProfileInfo.bank_account_name}}</p>

                                                </div>

                                                 <div>

                                                    <span class="spinner-border spinner-border-sm" role="status"
                                                        aria-hidden="true" v-if="searchingAccountName"></span>
                                                </div>
                  <div>
                    <div
                      style="display:flex; min-height:50px;align-items:center; justify-content:center"
                    
                      class="point-cursor  mt-2" 
                     
                    >
             
             <div  style="flex-basis:50%">


 <p   style="color:#877f7f; font-size:16px;" class="text-center point-cursor" @click="gotoMarketPlace">SKIP</p>
             </div>
      <div  style="flex-basis:50%">
               
                      <p :class="{'fade-cont': returnBvn }"  style="color:#0094FF; font-size:16px;" class=" text-center"    @click="updateBankDetails" v-if="!bvnLoader" >Continue   <img src="../../assets/images/rightarrowb.png" class="ml-2" height="17" v-if="!returnBvn">   <img src="../../assets/images/fadeArrow.png" class="ml-2" height="17" v-if="returnBvn">
                        
                       </p>
                       <p  class=" text-center">
 <i class="fa fa-spin fa-spinner text-center" style="color:#0094FF; font-size:16px;"   v-if="bvnLoader"></i>
                       </p>
                      
                      
             </div>
                
                    
                    
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>

  
      </div>
    </Wrapper>
  </div>
</template>

<script>
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import Footer from "../partials/Footer";
import Wrapper from "../partials/Wrapper";
import trans from "../../../translations";
    import {
        profileMixin
    } from '../dashboard/mixins/profileMixin.js';
import { commonJs } from "../dashboard/mixins/commonJs.js";

import { mapState } from "vuex";

import moment from "moment";
import http from "../../http/index";
import api from "../../apis/index";
export default {
  mixins: [commonJs,profileMixin],
  data() {
    return {
      tran: {},
      bvn: "",
      termsPrivacy: false,
      bookAmmount:'',
      bvnLoader:false,
      fadeBvnStatus: true,
      redirectToMarket: false,
    };
  },
  methods: {
    sendToMarketPlace() {
      this.$router.push("/market");
    },

    getBvnValue(e) {
   if(this.bvn.length == 11){
this.fadeBvnStatus = false
   }

   else{
this.fadeBvnStatus = true

   }
      

    },
  
    sendToWallet() {
      this.$router.push({
        path: "/wallet",
        query: { openFundWallet: true, fundAmount: this.amount }
      });
    },
    gotoTerms() {
      this.$router.push({
        path: "/terms",
      });
    },
    gotoMarketPlace() {
    if(this.bvnRedirectRoute == 'market' || this.redirectToMarket){
                      this.$router.push("/market");


}else{
    this.$router.go(-1)
         this.$store.dispatch("auth/getUserProfile");
}
    },

    gotoHome(){

      this.$router.push({path: "/book",});
    },

        submitBvn() {

            let bvnData = {
     bvn: this.bvn
               
            }
this.bvnLoader = true
            let vm = this
           
            http.patch(api.saveBvn, bvnData)

                .then(response => {
                    vm.$store.commit("market/stopPreloader");
                 vm.$toast.success({
        title:'KYC Completion',
        timeOut: 6000,
        message: "BVN submitted successfully",
        position: "top center",
      });
     
if(vm.bvnRedirectRoute == 'market' || this.redirectToMarket){
                      this.$router.push("/market");


}else{
    vm.$router.go(-1)
        vm.$store.dispatch("auth/getUserProfile");
}
  localStorage.setItem('redirectTomarket', false)
                      vm.$helpers.log(response);
this.bvnLoader = false

                })

                .catch(error => {
     
                    if (error.response) {

                        if (error.response.data.message !== "Unauthenticated.") {
                            vm.$store.commit("domElements/openNotificationMessage")
                            vm.$store.commit("domElements/saveNotificationMessage", error.response.data.message)
                                 vm.$helpers.log(error.response);
                                     vm.$store.commit("market/stopPreloader");
                        }
                    }
this.bvnLoader = false

                });

        },

  },
  components: {
    Navbar,
    Footer,
    Sidebar,
    Wrapper
  },

  computed: {
   
    returnMinAmount() {
      if (this.minAmount.toString().replace(/,/g, "") % 1 !== 0) {
        return this.minAmount.toString().replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
      } else {
        return this.minAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    returnBvn() {
     return this.fadeBvnStatus
    },
  
  },
  mounted() {

      let redirectToMarket = localStorage.getItem('redirectTomarket')
    this.redirectToMarket = JSON.parse(redirectToMarket) 

    let heading = {
      main: 'Complete your KYC'
    };
     this.$store.dispatch("market/getBookingHistory");
    this.$store.commit("market/savePageHeading", heading);
    this.$store.dispatch("market/getBookingSettings");
    this.$store.dispatch("market/getBookFundsSummary");
   this.$store.commit(
      "auth/saveBookRouteVisited",
      this.$route.path
    );
  },

 
};
</script>

<style scoped>
.booking-card {
  background: #fff;
  min-height: 50px;
  border-radius: 10px;
  padding: 10px;

  overflow: hidden !important;
}
.book-home-cover {
  min-height: 90vh;
  display: flex;
  align-items: center;
  padding-bottom: 5%;
}
.fade-cont{

  color: #E0E0E0 !important;
  pointer-events: none !important;
}
</style>
